import request from '@/utils/request'

const http = request()

/**
 * 用户登录
 * @param {String} username
 * @param {String} password
 * @returns
 */
export const login = (username, password) => http.post('/login', { username, password })

/**
 * 验证码登录
 * @param {String} phone
 * @param {String} smscode
 * @returns
 */
export const loginBySms = (phone, smscode) => http.post('/loginBySms', { phone, smscode })

/**
 * 用户注册
 * @param {String} username
 * @param {String} password
 * @returns
 */
export const userRegist = (username, password, phone, smscode) => http.post('/user/regist', { username, password, phone, smscode })

/**
 * 提交审核
 * @param {Object} params
 * @returns
 */
export const userApply = params => http.raw('/user/apply', params)

export const fetchById = id => http.post(`/user/${id}`)

export const saveUser = params => http.raw('/user/save', params)

/**
 * 验证码登录
 * @param {String} phone
 * @param {String} smscode
 * @returns
 */
export const changePwd = ({ userId, oldPwd, newPwd }) => http.post('/user/changePwd', { userId, oldPwd, newPwd })

export const submitResetPwd = params => http.post('/user/resetPwd/submit', params)

export const validResetPwd = params => http.post('/user/resetPwd/valid', params)

export const loginSms = params => http.post('/user/sms/send/login', params)

export const registSms = params => http.post('/user/sms/send/regist', params)

export const resetSms = params => http.post('/user/sms/send/reset', params)

export const changePhoneSms = params => http.post('/user/sms/send/change', params)

export const changePhone = ({ userId, phone, newPhone, smscode }) => http.post('/user/changePhone', { userId, phone, newPhone, smscode })
