<template>
  <div class="page page-project">
    <div class="fit v-px-md">
      <a-tabs type="card" v-model="tab" @change="handleTabChange">
        <a-tab-pane key="started" tab="执行中"></a-tab-pane>
        <a-tab-pane key="unstarted" tab="申报中" force-render></a-tab-pane>
        <a-tab-pane key="closed" tab="已结束"></a-tab-pane>
        <a-space slot="tabBarExtraContent">
          <a-button icon="sync" type="danger" size="small" @click="$refs.child.load()">刷新</a-button>
          <a-button size="small" type="primary" icon="form" @click="handleApplyProject">项目申报</a-button>
          <a-dropdown size="small">
            <a-menu slot="overlay" @click="handleMenuClick">
              <template v-if="node1Templates.length > 0">
                <a-menu-item key="01" disabled>申报书</a-menu-item>
                <a-menu-item :key="item.id" v-for="item in node1Templates" title="点击下载">
                  <span class="text-indigo font-xs">【{{ item.projectSubject }}】{{ item.fileOriginName }}</span>
                </a-menu-item>
                <a-menu-divider />
              </template>
              <template v-if="node2Templates.length > 0">
                <a-menu-item key="02" disabled>中期报告</a-menu-item>
                <a-menu-item :key="item.id" v-for="item in node2Templates" title="点击下载">
                  <span class="text-indigo font-xs">【{{ item.projectSubject }}】{{ item.fileOriginName }}</span>
                </a-menu-item>
                <a-menu-divider />
              </template>
              <template v-if="node3Templates.length > 0">
                <a-menu-item key="03" disabled>结项报告</a-menu-item>
                <a-menu-item :key="item.id" v-for="item in node3Templates" title="点击下载">
                  <span class="text-indigo font-xs">【{{ item.projectSubject }}】{{ item.fileOriginName }}</span>
                </a-menu-item>
                <a-menu-divider />
              </template>
            </a-menu>
            <a-button size="small" type="primary" icon="cloud-download">项目文档模板</a-button>
          </a-dropdown>
        </a-space>
      </a-tabs>
      <!-- <router-view></router-view> -->
      <compontent :is="currentTabComponent" ref="child"/>
    </div>
  </div>
</template>

<script>
import { fetchById } from '@/api/user'
import { listTemplate } from '@/api/main'
import ProjectStarted from './started'
import ProjectUnstarted from './unstarted'
import ProjectClosed from './closed'
const templateSort = (a, b) => {
  return a.projectSubject.localeCompare(b.projectSubject, 'zh-CN')
}
export default {
  namne: 'Project',
  data() {
    return {
      tab: undefined,
      currentTabComponent: null,
      node1Templates: [],
      node2Templates: [],
      node3Templates: [],
      templates: [],
    }
  },
  watch: {
    $route() {
      this.tab = this.$route.query.tab || 'started'
    },
    tab(key) {
      if (key === 'started') {
        this.currentTabComponent = ProjectStarted
      } else if (key === 'unstarted') {
        this.currentTabComponent = ProjectUnstarted
      } else if (key === 'closed') {
        this.currentTabComponent = ProjectClosed
      }
    },
  },
  methods: {
    initTabKey() {
      let { path } = this.$route
      this.tab = path.substring(path.lastIndexOf('/') + 1)
    },
    initTemplates() {
      let params = {
        pageSize: -1,
      }
      listTemplate(params).then(({ data }) => {
        if (data.success) {
          let templates = data.result.records
          let node1Templates = templates.filter(t => t.projectNode === 1)
          let node2Templates = templates.filter(t => t.projectNode === 2)
          let node3Templates = templates.filter(t => t.projectNode === 3)
          node1Templates.sort(templateSort)
          node2Templates.sort(templateSort)
          node3Templates.sort(templateSort)
          this.node1Templates = node1Templates
          this.node2Templates = node2Templates
          this.node3Templates = node3Templates
          this.templates = templates
        }
      })
    },
    handleTabChange(key) {
      // this.tab = key
      // if (key === 'started') {
      //   this.currentTabComponent = ProjectStarted
      // } else if (key === 'unstarted') {
      //   this.currentTabComponent = ProjectUnstarted
      // } else if (key === 'closed') {
      //   this.currentTabComponent = ProjectClosed
      // }
      this.$router.push({ path: 'list', query: { tab: key } })
    },
    handleMenuClick({ key }) {
      let file = this.templates.find(t => t.id === +key)
      this.downloadFile({ fileId: file.id, fileSign: 'T01', fileName: file.fileOriginName })
    },
    handleApplyProject() {
      let { id } = this.userinfo
      fetchById(id).then(({ data }) => {
        if (data.success) {
          let user = data.result
          if (user.state === 2) {
            this.$router.push({ path: '/usercenter/profile', query: { refer: 'project' } })
          } else {
            this.$router.push('/usercenter/project/apply')
          }
        }
      })
    },
  },
  mounted() {
    this.tab = this.$route.query.tab || 'started'
    this.initTemplates()
  },
}
</script>
