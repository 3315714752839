<template>
  <div class="page page-roadshow-list" s>
    <a-spin :spinning="loading">
      <div class="list-table-container" style="min-height: 200px;" v-if="list.length">
        <div class="project-item" v-for="item in list" :key="item.id" @click.stop="$router.push(`view?id=${item.id}`)">
          <div class="project-item-title flex items-center">
            <router-link :to="{ path: 'view', query: { id: item.id } }" :title="item.projectName">{{ item.projectName }}</router-link>
            <a-tag class="tag-tiny" color="#e10" v-if="item.state === 5">资助执行中</a-tag>
            <a-tag class="tag-tiny" color="#e10" v-else-if="item.state === 6">项目结项中</a-tag>
            <!-- <div class="v-ml-auto">
            申请人：
            <span v-if="item.applicant">{{ item.applicant.realName }}</span>
          </div> -->
          </div>
          <div class="project-item-content v-pa-md">
            <div class="flex items-center justify-around">
              <div class="project-item-field">
                <div class="item-field-img">
                  <a-icon type="account-book" />
                </div>
                <div class="item-field-value">
                  <div>
                    <span class="item-field-name">资助总金额</span>
                    <b class="font-special v-mx-xs">{{ $utils.thousands(item.amount, 0) }}</b>元
                  </div>
                  <div class="v-my-xs">
                    <span class="item-field-name">已收款金额</span>
                    <b class="font-special v-mx-xs">0</b>元
                  </div>
                  <div>
                    <span class="item-field-name">未收款金额</span>
                    <b class="text-red font-special v-mx-xs">0</b>元
                  </div>
                </div>
              </div>
              <div class="project-item-field">
                <div class="item-field-img">
                  <a-icon type="folder-open" />
                </div>
                <div class="item-field-value">
                  <div>
                    <span class="item-field-name">逾期未交文档</span>
                    <b class="text-red font-special v-mx-xs">{{ item.overReports }}</b>份
                  </div>
                  <div class="v-my-xs">
                    <span class="item-field-name">未提交的文档</span>
                    <b class="text-primary font-special v-mx-xs">{{ item.uncheckReports }}</b>份
                  </div>
                  <div>
                    <span class="item-field-name">已提交的文档</span>
                    <b class="font-special v-mx-xs">{{ item.checkedReports }}</b>份
                  </div>
                </div>
              </div>
              <div class="project-item-field">
                <div class="item-field-img">
                  <a-icon type="history" />
                </div>
                <div class="item-field-value">
                  <div>
                    <span class="item-field-name">项目周期</span>
                    <span class="font-special">
                      {{ item.startDate }}<template v-if="item.endDate">~{{ item.endDate }}</template>
                    </span>
                  </div>
                  <div class="v-my-xs">
                    <span class="item-field-name">历时</span>
                    <b class="font-special v-mx-xs">{{ item.period }}</b>个月
                  </div>
                  <div>
                    <!-- <span class="item-field-name">期限：</span> -->
                    <span v-if="item.restDays > 0">
                      剩余<b class="text-indigo font-special v-mx-xs">{{ item.restDays }}</b>天
                    </span>
                    <span class="text-red" v-else>
                      逾期
                      <b class="font-special v-mx-xs">{{ item.restDays }}</b>
                      天
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="list-toolbar flex items-center">
          <div class="list-toolbar-extra">
            共
            <span class="text-primary font-special">{{ pagination.total }}</span>
            条记录
          </div>
          <!-- hide-on-single-page -->
          <div class="list-pagination v-ml-auto">
            <a-pagination size="small" :current="pagination.pageNum" :total="pagination.total" :pageSize="pagination.pageSize" show-size-changer show-quick-jumper @change="onPageChange" @showSizeChange="onPageChange" />
          </div>
        </div>
      </div>
      <div style="padding: 60px 0" v-else>
        <a-empty :image="simpleImage"></a-empty>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import { getProjects } from '@/api/main'
export default {
  name: 'ProjectStarted',
  data() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      loading: false,
      list: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    }
  },
  methods: {
    handleSearch() {
      this.load()
    },
    handleReset() {
      this.form.resetFields()
    },
    onDataRangeChange(date, dateString) {
      let [startDate, endDate] = dateString
      this.form.rsBegin = startDate
      this.form.rsEnd = endDate
    },
    load(pageNum = 1, pageSize = 10, sorter) {
      this.loading = true
      let params = {
        pageNum,
        pageSize,
        sorter,
        states: '5,6',
        creator: this.userinfo.userName,
      }
      getProjects(params)
        .then(({ data }) => {
          if (data.success) {
            let { current, size, total, records } = data.result
            records.forEach(item => {
              let { reports } = item
              let checkedReports = reports.filter(report => report.state === 2)
              let uncheckReports = reports.filter(report => report.state !== 2)
              item.checkedReports = checkedReports.length
              item.uncheckReports = uncheckReports.length
              item.overReports = uncheckReports.filter(report => report.restDays < 0).length
              // if (period) {
              //   item.period = Math.round(period / 30)
              // }
            })
            this.list = records
            this.pagination = {
              pageNum: current,
              pageSize: size,
              total,
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onPageChange(pageNum, pageSize) {
      this.load(pageNum, pageSize)
    },
    handleApply(id) {
      this.$router.push(`apply?id=${id}`)
    },
  },
  created() {
    this.load()
  },
}
</script>

<style lang="stylus" scoped></style>
