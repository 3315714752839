<template>
  <div class="page page-roadshow-list">
    <div class="list-table-container">
      <!-- <div class="list-toolbar flex items-center">
        <div class="list-toolbar-extra">
          共
          <span class="text-primary font-special">{{ pagination.total }}</span>
          条记录
        </div>
        <div class="list-pagination v-ml-auto">
          <a-pagination size="small" :current="pagination.pageNum" :total="pagination.total" :pageSize="pagination.pageSize" show-size-changer show-quick-jumper @change="onPageChange" @showSizeChange="onPageChange" />
        </div>
      </div> -->
      <a-table class="list-table" size="small" :bordered="false" :pagination="false" :loading="loading" :columns="columns" :data-source="list" :scroll="{ x: '100%' }" rowKey="id">
        <template slot="state" slot-scope="text, record">
          <div class="text-red-5">
            <template v-if="text === 1">
              <div>待提交项目意向书</div>
              <a-popover placement="rightBottom" v-if="record.reviewResult === 0" title="审核意见">
                <span>（评审已驳回）</span>
                <template slot="content">{{ record.reviewOpinion }}</template>
              </a-popover>
            </template>
            <template v-else-if="text === 2">项目意向待评审</template>
            <template v-else-if="text === 3">
              <template v-if="record.stateNode === 4">项目申请书待审核</template>
              <template v-else>
                <div>待提交项目申请书</div>
                <a-popover placement="rightBottom" v-if="record.checkResult === 0" title="审核意见">
                  <span>（评审已驳回）</span>
                  <template slot="content">{{ record.checkOpinion }}</template>
                </a-popover>
              </template>
            </template>
            <template v-else-if="text === 4">待签订协议</template>
          </div>
        </template>
        <template slot="range" slot-scope="text, record">
          <span>{{ record.startDate }}<template v-if="record.endDate">~{{record.endDate }}</template></span>
        </template>
        <template slot="period" slot-scope="text, record">
          <span>{{ record.period }}</span>
        </template>
        <template slot="address" slot-scope="text, record">
          <span :title="$utils.flatAddress(record.addresses)">{{ $utils.flatAddress(record.addresses) }}</span>
        </template>
        <template slot="project" slot-scope="text, record">
          <router-link class="text-indigo" :title="text" :to="{ path: 'view', query: { id: record.id } }">{{ text || record.topic }}</router-link>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space class="font-xs">
            <!-- 申报 -->
            <template v-if="record.state === 1">
              <span class="hover-line text-indigo" title="编辑" @click="handleApply(record.id)">编辑</span>
              <!-- <span class="hover-line text-red" title="撤销">撤销</span> -->
            </template>
            <!-- 项目审核 -->
            <router-link :to="{ path: 'view', query: { id: record.id } }" v-else-if="record.state === 2 || record.state === 4" class="hover-line text-indigo" title="查看">查看</router-link>
            <!-- 项目申请书 -->
            <template v-else-if="record.state === 3">
              <router-link :to="{ path: 'view', query: { id: record.id } }" class="hover-line text-indigo" title="查看" v-if="record.stateNode === 4">查看</router-link>
              <router-link :to="{ path: 'declare', query: { id: record.id } }" class="hover-line text-indigo" title="编辑" v-else>编辑</router-link>
            </template>
          </a-space>
        </template>
      </a-table>
      <div class="list-toolbar flex items-center">
        <div class="list-toolbar-extra">
          共
          <span class="text-primary font-special">{{ pagination.total }}</span>
          条记录
        </div>
        <!-- hide-on-single-page -->
        <div class="list-pagination v-ml-auto">
          <a-pagination size="small" :current="pagination.pageNum" :total="pagination.total" :pageSize="pagination.pageSize" show-size-changer show-quick-jumper @change="onPageChange" @showSizeChange="onPageChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getProjects } from '@/api/main'
export default {
  data() {
    return {
      loading: false,
      list: [],
      columns: [
        {
          title: '#',
          dataIndex: 'id',
          width: 55,
          align: 'center',
          // fixed: 'left',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '项目名称 / 项目议题',
          ellipsis: true,
          // width: 300,
          // fixed: 'left',
          dataIndex: 'projectName',
          scopedSlots: { customRender: 'project' },
        },
        {
          title: '板块',
          dataIndex: 'subject',
          width: 100,
          align: 'center',
        },
        {
          title: '项目周期',
          dataIndex: 'startDate',
          width: 185,
          align: 'center',
          scopedSlots: { customRender: 'range' },
        },
        {
          title: '历时（月）',
          dataIndex: 'period',
          align: 'center',
          width: 90,
          scopedSlots: { customRender: 'period' },
        },
        {
          title: '拟申请金额（元）',
          dataIndex: 'amount',
          align: 'right',
          width: 140,
          customRender: text => (text ? this.$utils.thousands(text) : text),
        },
        {
          title: '项目实施地点',
          dataIndex: 'addr',
          ellipsis: true,
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '项目状态',
          dataIndex: 'state',
          align: 'center',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'state' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' },
        },
      ],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    }
  },
  methods: {
    handleSearch() {
      this.load()
    },
    handleReset() {
      this.form.resetFields()
    },
    onDataRangeChange(date, dateString) {
      let [startDate, endDate] = dateString
      this.form.rsBegin = startDate
      this.form.rsEnd = endDate
    },
    load(pageNum = 1, pageSize = 10, sorter) {
      this.loading = true
      let params = {
        pageNum,
        pageSize,
        sorter,
        states: '0,1,2,3,4',
        creator: this.userinfo.userName,
      }
      getProjects(params)
        .then(({ data }) => {
          if (data.success) {
            let { current, size, total, records } = data.result
            records.forEach(item => {
              let { period } = item
              if (period) {
                item.months = Math.round(period / 30)
              }
            })
            this.list = records
            this.pagination = {
              pageNum: current,
              pageSize: size,
              total,
            }
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onPageChange(pageNum, pageSize) {
      this.load(pageNum, pageSize)
    },
    handleApply(id) {
      this.$router.push(`apply?id=${id}`)
    },
  },
  mounted() {
    this.load()
  },
}
</script>
